body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 4px; /* Adjust the width of the scrollbar */
  height: 8px; /* Adjust the height for horizontal scrollbar, if needed */
}

/* Style the track (background of the scrollbar) */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 10px; /* Rounded edges */
}

/* Style the thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded edges of the thumb */
}

/* Change thumb color on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
}
